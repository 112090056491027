angular.module('enervexSalesappApp').controller('LocalLocalController', function($scope, $uibModalInstance, parsedDesign, design) {
	$scope.ok = function() {
		var modalInstance = $uibModalInstance.close({
			design: design,
			selected: "design"
		});
	}
	$scope.dismiss = function($event) {
		$event.preventDefault()
		var modalInstance = $uibModalInstance.close({
			design: parsedDesign,
			selected: "parsedDesign"
		});
	}
});